<template>
  <section class="suppliers-edit-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="supplierData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching supplier data
      </h4>
      <div class="alert-body">
        No supplier found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-suppliers-list'}"
        >
          Suppliers
        </b-link>
        list.
      </div>
    </b-alert>

    <b-row v-if="supplierData">

      <b-col
        cols="12"
      >
        <b-card>
          <supplier-header
            :supplier-data="supplierOriginalData"
            :can-edit-supplier="false"
            :can-edit-status="false"
            :core-competencies-labels="coreCompetenciesLabels"
          />
        </b-card>

        <div class="d-flex align-items-center mb-2">
          <b-button
            variant="outline-primary"
            size="md"
            class="mr-2"
            @click="goBack"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="16"
              class="mr-2"
            />Back
          </b-button>
          <h4 class="mb-0">
            {{ headerTitle }}
          </h4>
        </div>
        <div
          class="accordion"
          role="tablist"
        >
          <b-card
            v-for="(section, index) in availableSections"
            :key="index"
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                block
                variant="outline-primary"
                class="d-flex justify-content-start align-items-center"
                @click="setActiveSection(index.toString())"
              >
                <b-badge variant="dark mr-1">
                  {{ index + 1 }}
                </b-badge>
                {{ section.title }}
              </b-button>
            </b-card-header>
            <b-collapse
              :id="index.toString()"
              :visible="activeSection === index.toString()"
              accordion="items"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <b-form-group
                    class="ml-4"
                  >
                    <b-form-checkbox-group
                      v-model="selectedOptions[section.title]"
                      :options="section.options"
                      :name="section.title"
                      stacked
                    />
                  </b-form-group>
                  <b-button
                    variant="primary"
                    @click="() => saveOptions(index)"
                  >Next</b-button>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div
          v-if="activeSection === ''"
          class="d-flex align-items-center mb-2"
        >
          <b-button
            variant="primary"
            size="md"
            class="mr-2"
            @click="goBack"
          >
            Save
          </b-button>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BAlert, BBadge, BButton, BCard, BCardBody, BCardHeader, BCardText, BCol, BCollapse,
  BFormGroup, BFormCheckboxGroup, BLink, BRow,
} from 'bootstrap-vue'
import {
  computed, onMounted, onUnmounted, ref,
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import supplierStoreModule from '../supplierStoreModule'
import SupplierHeader from './SupplierHeader.vue'

export default {
  components: {
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCol,
    BCollapse,
    BFormCheckboxGroup,
    BFormGroup,
    BLink,
    BRow,

    SupplierHeader,
  },
  setup() {
    const toast = useToast()

    const coreCompetenciesLabels = [
      { key: 'designer_services', label: 'Designer Services' },
      { key: 'garment_manufacturing_techniques', label: 'Garment Manufacturing Techniques' },
      { key: 'fabrics_specialties', label: 'Fabrics Specialties' },
      { key: 'trims_accessories', label: 'Trims/Accessories' },
      { key: 'printing_embroidery_techniques', label: 'Printing/Embroidery Techniques' },
      { key: 'label_services', label: 'Label Services' },
      { key: 'packaging_services', label: 'Packaging Services' },
    ]

    const coreTitles = {
      designer_services: 'Designer Services',
      garment_manufacturing_techniques: 'Garment Manufacturing Techniques',
      fabrics_specialties: 'Fabrics Specialties',
      trims_accessories: 'Trims/Accessories',
      printing_embroidery_techniques: 'Printing/Embroidery Techniques',
      label_services: 'Label Services',
      packaging_services: 'Packaging Services',
    }

    const core = ref(router.currentRoute.params.core)

    const activeSection = ref('0')

    const supplierData = ref(null)
    const supplierOriginalData = ref(null)
    const selectedOptions = ref({})

    const userRole = JSON.parse(localStorage.getItem('userData')).role

    const SUPPLIER_APP_STORE_MODULE_NAME = 'app-supplier'

    // Register module
    if (!store.hasModule(SUPPLIER_APP_STORE_MODULE_NAME)) {
      store.registerModule(SUPPLIER_APP_STORE_MODULE_NAME, supplierStoreModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUPPLIER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(SUPPLIER_APP_STORE_MODULE_NAME)
      }
    })
    const sections = {
      designer_services: [
        {
          title: 'Initial Design Concept',
          options: ['Sketch to design', 'Garment design', 'Fabric Selection'],
        },
        {
          title: 'Technical Design',
          options: ['CAD design', 'Tech packs / Specs', 'Size grading',
            '2D Pattern making', 'Digital Design 3D', 'VR', 'AR', 'XR'],
        },
        {
          title: 'Design Detailing',
          options: ['Print Design', 'Fabric Design'],
        },
        {
          title: 'Market Analysis',
          options: ['Trend Forecasting'],
        },
      ],
      garment_manufacturing_techniques: [
        {
          title: 'Seaming Techniques',
          options: ['Fabric Bonding / Bonded seams', 'Flatlock seams',
            'Sealed seams', 'Overlock', 'Topstitching', 'Straightstich',
            'French seam', 'Pleating'],
        },
        {
          title: 'Cutting Techniques',
          options: ['Laser cutting', 'Single ply cutting', 'Stack cutting',
            'Video mapping cutting'],
        },
        {
          title: 'Fabric Treatments',
          options: ['Stone Washing / Enzyme washing', 'Working with fine fabrics',
            'Working with thin / fine fabrics'],
        },
        {
          title: 'Knitting Techniques',
          options: ['Seamless / Tubular'],
        },
        {
          title: 'Pattern Services',
          options: ['Pattern making', 'Pattern grading', 'Pattern digitising',
            'Patterns - Audaces', 'Patterns - Assyst', 'Patterns - Lectra',
            'Patterns - Gerber'],
        },
        {
          title: 'CAD Services',
          options: ['Audaces', 'Lectra', 'Gerber'],
        },
      ],
      fabrics_specialties: [
        {
          title: 'Natural Fibers',
          options: ['Cotton', 'Silk', 'Linen', 'Wool', 'Organic fabrics'],
        },
        {
          title: 'Synthetic Fibers',
          options: ['Acrylic', 'Polyester', 'Nylon', 'Rayon', 'Spandex/Lycra',
            'Viscose'],
        },
        {
          title: 'Blended Fibers',
          options: ['Jersey (can be cotton or synthetic)', 'Chambray', 'Crepe',
            'Georgette'],
        },
        {
          title: 'Performance Fabrics',
          options: ['Sports fabrics', 'Mesh', 'Net/Powernet', 'Spandex/Lycra',
            'Industrial fabrics'],
        },
        {
          title: 'Luxury Fabrics',
          options: ['Silk', 'Velvet', 'Satin', 'Taffeta', 'Faux Fur', 'Fur',
            'Sequins'],
        },
        {
          title: 'Textured Fabrics',
          options: ['Jacquard', 'Chiffon', 'Organza', 'Tweed', 'Faux Leather',
            'Suede'],
        },
        {
          title: 'Heavy Duty Fabrics',
          options: ['Denim', 'Twill', 'Fleece', 'Quilted fabrics', 'Wadding'],
        },
        {
          title: 'Functional Fabrics',
          options: ['Interlining', 'Lining', 'Scuba', 'Fabric washing',
            'Fabric dying'],
        },
        {
          title: 'Decorative Fabrics',
          options: ['Sequins', 'Fur', 'Faux Fur', 'Lace', 'Embroidery'],
        },
        {
          title: 'Eco-Friendly Fabrics',
          options: ['Organic fabrics', 'Recycled fabrics'],
        },
        {
          title: 'Fabric Treatments',
          options: ['Prints', 'Printing on own fabrics', 'Stripes', 'Checkers',
            'Fabric dying', 'Fabric washing'],
        },
      ],
      trims_accessories: [
        {
          title: 'Fasteners',
          options: ['Snap buttons', 'Buttons - custom', 'Plastic buttons',
            'Metal buttons', 'Wooden buttons', 'Hook and eye', 'Clasps',
            'Buckles'],
        },
        {
          title: 'Elastics',
          options: ['Elastics', 'Elastics - custom', 'Sublimated elastics',
            'Jacquard elastics'],
        },
        {
          title: 'Support Materials',
          options: ['Pads/Cups', 'Padding', 'Bones', 'Bone casing',
            'Bra cups/Padding', 'Wiring/Underwiring'],
        },
        {
          title: 'Zippers',
          options: ['Zips - custom', 'Zips - stock', 'Zips plastic',
            'Zips metal', 'Zips custom'],
        },
        {
          title: 'Rings and Adjusters',
          options: ['Rings and sliders', 'Rings/Slides/Hooks'],
        },
        {
          title: 'Fastening Tapes',
          options: ['Hook and loop', 'Bias binding', 'Tapes'],
        },
        {
          title: 'Decorative Elements',
          options: ['Fine Jewelry', 'Costume Jewelry', 'Metal Accessories',
            'Clear plastic accessories'],
        },
        {
          title: 'Functional Accessories',
          options: ['Straps', 'Hygiene stickers', 'Wadding'],
        },
        {
          title: 'Textile Accessories',
          options: ['Threads', 'Bias binding', 'Tapes', 'Wadding'],
        },
      ],
      printing_embroidery_techniques: [
        {
          title: 'Screen Printing',
          options: ['Screen printing', 'Roll to roll - Screen printing'],
        },
        {
          title: 'Digital Printing',
          options: ['Digital printing', 'DTG/Direct to garment',
            'Roll to roll - Digital printing'],
        },
        {
          title: 'Sublimation Printing',
          options: ['Sublimation printing', 'Roll to roll - Sublimation'],
        },
        {
          title: 'Transfer Printing',
          options: ['Transfer printing', 'Flex printing', 'Flock printing',
            'Foil printing'],
        },
        {
          title: 'Embellishments',
          options: ['Embroidery', 'Hand embroidery', 'Machine embroidery',
            'Bead embroidery', 'Rhinestone applying', 'Sequins', 'Appliques'],
        },
        {
          title: 'Specialty Printing',
          options: ['Flex printing', 'Flock printing', 'Foil printing',
            'Sequins'],
        },
      ],
      label_services: [
        {
          title: 'Standard Labels',
          options: ['Care Labels', 'Brand labels', 'Tags/Swing Tags'],
        },
        {
          title: 'Specialty Labels',
          options: ['Woven/Jacquard labels', 'Leather labels', 'Metal labels',
            'Rubber labels', 'Plastic labels', 'Satin/Nylon labels'],
        },
        {
          title: 'Decorative Details',
          options: ['Appliques', 'Stickers', 'Embossing'],
        },
        {
          title: 'Eletronic Labels',
          options: ['NFC Tags'],
        },
      ],
      packaging_services: [
        {
          title: 'Boxes and Containers',
          options: ['Box'],
        },
        {
          title: 'Flexible Packaging',
          options: ['Bag', 'Silk Paper'],
        },
        {
          title: 'Sealing Solutions',
          options: ['Tape'],
        },
        {
          title: 'Decorative Elements',
          options: ['Stickers', 'Postcards'],
        },
      ],
    }

    const setActiveSection = section => {
      activeSection.value = section
    }

    const availableSections = computed(() => sections[core.value] || [])
    const headerTitle = computed(() => coreTitles[core.value] || core.value)

    const fetchSupplier = () => {
      store.dispatch('app-supplier/fetchSupplier', { id: router.currentRoute.params.id })
        .then(response => {
          supplierData.value = response.data
          supplierOriginalData.value = JSON.parse(JSON.stringify(supplierData.value))
          selectedOptions.value = supplierData.value[core.value] || {}
        })
        .catch(error => {
          if (error.response.status === 404) {
            supplierData.value = undefined
          }
        })
    }

    const goBack = () => {
      router.go(-1)
    }

    const openNextSection = index => {
      const totalAvailableSections = availableSections.value.length - 1
      if (index < totalAvailableSections) {
        activeSection.value = (index + 1).toString()
      } else {
        activeSection.value = ''
      }
    }

    const saveOptions = index => {
      const fn = x => x.length !== 0
      const filter = Object.entries(selectedOptions.value).filter(([k, v]) => fn(v, k))
      const filteredOptions = Object.fromEntries(filter)
      store.dispatch('app-supplier/editSupplier', {
        id: router.currentRoute.params.id,
        [core.value]: filteredOptions,
      })
        .then(() => {
          const selectedTitle = availableSections.value[index].title
          fetchSupplier()
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${selectedTitle} saved`,
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        }).then(() => openNextSection(index))
    }

    onMounted(fetchSupplier)

    return {
      activeSection,

      supplierData,
      supplierOriginalData,
      selectedOptions,
      userRole,
      headerTitle,
      availableSections,
      coreCompetenciesLabels,
      goBack,
      setActiveSection,
      openNextSection,
      saveOptions,
    }
  },
}
</script>

<style lang="scss">
label {
  font-weight: 600;
}
.card-overflow-visible {
  overflow: visible!important;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
